import { createRouter, createWebHistory } from "vue-router";
import { useCustomerStore } from "../store/app/customer/index";

function guard(to, from, next) {
	useCustomerStore().token ? next() : next("/login");
}

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/flights/Search.vue"),
		meta: {
			hide: true,
			page_title: "Home",
		},
	},
	{
		path: "/about_us",
		name: "About Us",
		component: () => import("@/views/web/about_us.vue"),
		meta: {
			hide: true,
			page_title: "About Us",
		},
	},
	{
		path: "/services",
		name: "Our Services",
		component: () => import("@/views/web/our_services.vue"),
		meta: {
			hide: true,
			page_title: "Our Services",
		},
	},
	{
		path: "/services/details/:uuid",
		name: "Services Details",
		component: () => import("@/views/web/our_services_details.vue"),
		meta: {
			hide: true,
			page_title: "Our Services",
		},
	},
	{
		path: "/career",
		name: "Career",
		component: () => import("@/views/web/career.vue"),
		meta: {
			hide: true,
			page_title: "Career",
		},
	},
	{
		path: "/flights",
		name: "Flights",
		component: () => import("@/views/flights/SearchFlight.vue"),
		meta: {
			hide: true,
			page_title: "Flights",
		},
	},
	{
		path: "/flights/result/:id",
		name: "Flight Result",
		component: () => import("@/views/flights/Result.vue"),
		meta: {
			hide: true,
			page_title: "Flight Result",
		},
	},
	{
		path: "/flights/result/:id",
		name: "Flight Result Multi",
		component: () => import("@/views/flights/ResultMulti.vue"),
		meta: {
			hide: true,
			page_title: "Flight Result",
		},
	},
	{
		path: "/flights/results/:id",
		name: "Flight Multi",
		component: () => import("@/views/flights/ResultAmadeusMultiCities.vue"),
		meta: {
			hide: true,
			page_title: "Flight Result",
		},
	},
	{
		path: "/flights/reservation/:id",
		name: "Flight Reservation",
		component: () => import("@/views/flights/Reservation.vue"),
		meta: {
			hide: true,
			page_title: "Flight Reservation",
		},
	},
	{
		path: "/flights/confirmation/:id",
		name: "Flight Confirmation",
		component: () => import("@/views/flights/Confirmation.vue"),
		meta: {
			hide: true,
			page_title: "Flight Confirmation",
		},
	},
	{
		path: "/hotels",
		name: "Hotels",
		component: () => import("@/views/hotels/Search.vue"),
		meta: {
			hide: true,
			page_title: "Hotels",
		},
	},
	{
		path: "/hotels/result/:id",
		name: "Hotels Result",
		component: () => import("@/views/hotels/Result.vue"),
		meta: {
			hide: true,
			page_title: "Hotels Result",
		},
	},
	{
		path: "/hotels/details/:id",
		name: "Hotel Details",
		component: () => import("@/views/hotels/Details.vue"),
		meta: {
			hide: true,
			page_title: "Hotels Details",
		},
	},
	{
		path: "/hotels/book/:id",
		name: "Hotel Book",
		component: () => import("@/views/hotels/Book.vue"),
		meta: {
			hide: true,
			page_title: "Hotel Booking",
		},
	},
	{
		path: "/hotels/confirmation/:id",
		name: "Hotel Confirmation",
		component: () => import("@/views/hotels/Confirmation.vue"),
		meta: {
			hide: true,
			page_title: "Hotel Confirmation",
		},
	},
	{
		path: "/activities",
		name: "Activities",
		component: () => import("@/views/activities/Search.vue"),
		meta: {
			hide: true,
			page_title: "Activities",
		},
	},
	{
		path: "/activities/result/:id",
		name: "Activities Result",
		component: () => import("@/views/activities/Result.vue"),
		meta: {
			hide: true,
			page_title: "Activities Result",
		},
	},
	{
		path: "/activities/book",
		name: "Activities Booking",
		component: () => import("@/views/activities/Book.vue"),
		meta: {
			hide: true,
			page_title: "Activities Booking",
		},
	},
	{
		path: "/activities/confirmation/:id",
		name: "Activities Confirmation",
		component: () => import("@/views/activities/Confirmation.vue"),
		meta: {
			hide: true,
			page_title: "Activities Confirmation",
		},
	},
	{
		path: "/packages",
		name: "Vacation Packages",
		component: () => import("@/views/package/Package.vue"),
		meta: {
			hide: true,
			page_title: "Vacation Packages",
		},
	},
	{
		path: "/packages/details/:uuid",
		name: "Vacation Packages Details",
		component: () => import("@/views/package/Details.vue"),
		meta: {
			hide: true,
			page_title: "Vacation Packages Details",
		},
	},
	{
		path: "/insurance",
		name: "Insurance",
		component: () => import("@/views/insurance/Search.vue"),
		meta: {
			hide: true,
			page_title: "Insurance",
		},
	},
	{
		path: "/insurance/result/:id",
		name: "Insurance Result",
		component: () => import("@/views/insurance/Result.vue"),
		meta: {
			hide: true,
			page_title: "Insurance Result",
		},
	},
	{
		path: "/insurance/book/:id",
		name: "Insurance Book",
		component: () => import("@/views/insurance/Book.vue"),
		meta: {
			hide: true,
			page_title: "Insurance Book",
		},
	},
	{
		path: "/insurance/confirmation/:id",
		name: "Insurance Confirmation",
		component: () => import("@/views/insurance/Confirmation.vue"),
		meta: {
			hide: true,
			page_title: "Insurance Confirmation",
		},
	},

	{
		path: "/ferry",
		name: "Ferry",
		component: () => import("@/views/ferry/Search.vue"),
		meta: {
			hide: true,
			page_title: "Ferry",
		},
	},
	{
		path: "/ferry/result/:id",
		name: "Ferry Result",
		component: () => import("@/views/ferry/Result.vue"),
		meta: {
			hide: true,
			page_title: "Ferry Result",
		},
	},
	{
		path: "/ferry/book/:id",
		name: "Ferry Book",
		component: () => import("@/views/ferry/Book.vue"),
		meta: {
			hide: true,
			page_title: "Ferry Book",
		},
	},
	{
		path: "/ferry/confirmation/:id",
		name: "Ferry Confirmation",
		component: () => import("@/views/ferry/Confirmation.vue"),
		meta: {
			hide: true,
			page_title: "Ferry Confirmation",
		},
	},

	{
		path: "/manage",
		name: "Manage Booking",
		component: () => import("@/views/web/manage.vue"),
		meta: {
			hide: true,
			page_title: "Manage Booking",
		},
	},
	{
		path: "/terms",
		name: "Terms and Conditions",
		component: () => import("@/views/web/terms.vue"),
		meta: {
			hide: true,
			page_title: "Terms and Conditions",
		},
	},
	{
		path: "/privacy",
		name: "Privacy Policy",
		component: () => import("@/views/web/privacy.vue"),
		meta: {
			hide: true,
			page_title: "Privacy Policy",
		},
	},
	{
		path: "/contact_us",
		name: "Contact Us",
		component: () => import("@/views/web/contact.vue"),
		meta: {
			hide: true,
			page_title: "Contact Us",
		},
	},
	{
		path: "/logout",
		name: "Logout",
		component: () => import("@/views/auth/logout/index"),
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/auth/login/login.vue"),
		meta: {
			hide: true,
			page_title: "Login",
		},
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("@/views/auth/register/register.vue"),
		meta: {
			hide: true,
			page_title: "Register",
		},
	},
	{
		path: "/forgot_password",
		name: "Forgot Password",
		component: () => import("@/views/auth/forgot/forgot.vue"),
		meta: {
			hide: true,
			page_title: "Forgot Password",
		},
	},
	{
		path: "/reset-password/:token",
		name: "Reset Password",
		component: () => import("@/views/auth/forgot/reset.vue"),
		meta: {
			hide: true,
			page_title: "Reset Password",
		},
	},
	{
		path: "/email/verify/:id",
		name: "Verify Email",
		component: () => import("@/views/auth/verify/verify.vue"),
		meta: {
			hide: true,
			page_title: "Verify Email",
		},
	},
	{
		path: "/profile",
		component: () => import("@/Layout/my_account_app.vue"),
		beforeEnter: guard,
		children: [
			{
				path: "",
				name: "My Profile",
				component: () => import("@/views/my_account/profile.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "/bookings",
				name: "My Bookings",
				component: () => import("@/views/my_account/my_bookings.vue"),
				meta: {
					hide: true,
				},
				children: [
					{
						path: "",
						name: "Flight Bookings",
						component: () =>
							import("@/views/my_account/bookings/flights/ViewFlights.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "flights",
						name: "Flights Bookings",
						component: () =>
							import("@/views/my_account/bookings/flights/ViewFlights.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "flights/:id",
						name: "Flights Bookings Detail",
						component: () =>
							import("@/views/my_account/bookings/flights/FlightDetailsDB.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "hotels",
						name: "Hotel Bookings",
						component: () =>
							import("@/views/my_account/bookings/hotels/ViewHotels.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "hotels/:id",
						name: "Hotel Bookings Detail",
						component: () =>
							import("@/views/my_account/bookings/hotels/HotelDetails.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "insurance",
						name: "Insurance Bookings",
						component: () =>
							import("@/views/my_account/bookings/insurance/ViewInsurance.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "insurance/:id",
						name: "Insurance Bookings Detail",
						component: () =>
							import(
								"@/views/my_account/bookings/insurance/InsuranceDetails.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "packages",
						name: "Packages Bookings",
						component: () =>
							import("@/views/my_account/bookings/packages/ViewPackage.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "packages/:id",
						name: "Package Bookings Detail",
						component: () =>
							import("@/views/my_account/bookings/packages/PackageDetails.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "activities",
						name: "Activities Bookings",
						component: () =>
							import(
								"@/views/my_account/bookings/activities/ViewActivities.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "activities/:id",
						name: "Activities Bookings Detail",
						component: () =>
							import(
								"@/views/my_account/bookings/activities/ActivitiesDetails.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "ferry",
						name: "Ferry Bookings",
						component: () =>
							import("@/views/my_account/bookings/ferry/ViewFerry.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "ferry/:id",
						name: "Ferry Bookings Detail",
						component: () =>
							import("@/views/my_account/bookings/ferry/FerryDetails.vue"),
						meta: {
							hide: true,
						},
					},
				],
			},
			{
				path: "/document",
				name: "Travel Docuent",
				component: () => import("@/views/my_account/travel_document.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "/password",
				name: "Change Password",
				component: () => import("@/views/my_account/change_password.vue"),
				meta: {
					hide: true,
				},
			},
		],
	},
	{
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import("@/views/404.vue"),
		meta: {
			hide: true,
			page_title: "404",
		},
	},
	{
		path: "/error",
		name: "error",
		component: () => import("@/views/error.vue"),
		meta: {
			hide: true,
			page_title: "Error",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});
router.beforeEach((to, from, next) => {
	// Check if the to object has a meta field and the page_title property exists
	const titleText =
		to.meta && to.meta.page_title ? to.meta.page_title : "Flights Tickets";

	document.title = "OCI Travels and Tourism - " + titleText;

	// next();

	if (
		from.name === "Flight Result" &&
		to.name === "Flight Result" &&
		to.params.id !== from.params.id
	) {
		// Reload the page
		window.location.href = to.fullPath;
	} else {
		// Continue with the normal navigation
		next();
	}
});

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
