import "animate.css";
import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import VueFlatPickr from "vue-flatpickr-component";
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import vClickOutside from "click-outside-vue3";
import App from "./App.vue";
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueScrollTo from "vue-scrollto";
import axios from "axios";
import { globalData } from "./global.js";

async function loginWeb() {
  // Check if a token is already stored in localStorage
  const tokenItemString = localStorage.getItem("token");

  try {
    if (tokenItemString) {
      // Token found in localStorage, attempt to use it for authentication
      axios.defaults.baseURL = process.env.VUE_APP_API_URL;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + tokenItemString;

      // Check if the token is valid by sending an authentication request
      const response = await axios.post("/authentication");

      if (response.status === 200) {
        // console.log("AUTHORIZED");
      }
    } else {
      // Token not found in localStorage, attempt to log in

      axios.defaults.baseURL = process.env.VUE_APP_API_URL;

      const loginResponse = await axios.post("/web", {
        corporate_id: process.env.VUE_APP_CORPORATE_ID,
        username: process.env.VUE_APP_USERNAME,
      });

      const token = loginResponse.data.token;

      // Store the token item in localStorage for future use
      localStorage.setItem("token", token);
    }
  } catch (error) {
    // console.log("NOT AUTHORIZED");

    axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    const loginResponse = await axios.post("/web", {
      corporate_id: process.env.VUE_APP_CORPORATE_ID,
      username: process.env.VUE_APP_USERNAME,
    });

    const token = loginResponse.data.token;

    // Store the token item in localStorage for future use
    localStorage.setItem("token", token);
  }
}

async function fetchData() {
  const tokenItemString = localStorage.getItem("token");

  const token = tokenItemString;

  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    // Make an API call to the backend endpoint
    const response = await axios.post("/backend/color_set");

    return response.data; // Make sure to set the value here
  } catch (error) {
    // Handle any errors that might occur during the API call
    // console.error("Error fetching data:", error);
    // Return a default value or handle the error case as needed
    return null;
  }
}

async function getScripts() {
  const tokenItemString = localStorage.getItem("token");

  const token = tokenItemString;

  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post("/scripts");
    const javascriptCode = response.data.javascript;

    // Create a script element
    const scriptElement = document.createElement("script");

    // Set the JavaScript code as the content of the script element
    scriptElement.innerHTML = javascriptCode;

    // Append the script element to the document body
    document.body.appendChild(scriptElement);
  } catch (error) {
    console.error("Error fetching scripts:", error);
    // Handle error as needed
  } finally {
    // Optionally, you can update a loading state here
  }
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
  document.body.classList.add("dark");
  store.state.theme = "dark";
  store.state.isDark = true;
} else {
  document.body.classList.add("light");
  store.state.theme = "light";
  store.state.isDark = false;
}
if (localStorage.semiDark === "true") {
  document.body.classList.add("semi-dark");
  store.state.semidark = true;
  store.state.semiDarkTheme = "semi-dark";
} else {
  document.body.classList.add("semi-light");
  store.state.semidark = false;
  store.state.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
  store.state.menuLayout = "horizontal";
} else {
  store.state.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
  store.state.skin = "bordered";
  document.body.classList.add("skin--bordered");
} else {
  store.state.skin = "default";
  document.body.classList.add("skin--default");
}
// check direction for localstorage
if (localStorage.direction === "true") {
  store.state.direction = true;

  document.documentElement.setAttribute("dir", "rtl");
} else {
  store.state.direction = false;
  document.documentElement.setAttribute("dir", "ltr");
}

(async function () {
  try {
    // Fetch the data from the backend using fetchData
    await loginWeb();
    globalData.asyncDataValue = await fetchData();
    await getScripts();

    // Continue with the Vue app setup
    createApp(App)
      .use(store)
      .use(VueSweetalert2)
      .use(Toast, {
        toastClassName: "dashcode-toast",
        bodyClassName: "dashcode-toast-body",
      })
      .use(createPinia())
      .use(pinia)
      .use(router)
      .use(VueClickAway)
      .use(vClickOutside)
      .use(VueTippy)
      .use(VueFlatPickr)
      .use(VueGoodTablePlugin)
      .use(VueApexCharts)
      .use(VCalendar)
      .use(VueScrollTo)
      .provide("globalData", globalData) // Provide globalData directly
      .mount("#app");
  } catch (error) {
    // console.log(error);
    // Handle any errors that might occur during the data fetching process
    // console.error("Error setting asyncDataValue:", error);
  }
})();
