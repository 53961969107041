import axios from "axios";
import { defineStore } from "pinia";

export const useCustomerStore = defineStore("customer", {
	state: () => ({
		token: null,
		first_name: null,
		last_name: null,
		email: null,
	}),
	actions: {
		setCustomerDetails(res) {
			this.$state.token = res.data.token;
			this.$state.first_name = res.data.first_name;
			this.$state.last_name = res.data.last_name;
			this.$state.email = res.data.email;
		},

		async fetchCustomer() {
			let res = await axios.get("api/users/" + this.$state.token);

			this.$state.token = res.data.user.token;
			this.$state.first_name = res.data.user.first_name;
			this.$state.last_name = res.data.user.last_name;
			this.$state.email = res.data.user.email;
		},

		clearCustomer() {
			this.$state.token = null;
			this.$state.first_name = null;
			this.$state.last_name = null;
			this.$state.email = null;
		},
	},
	persist: true,
});
